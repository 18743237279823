.footerbgColor {
  background: #333333;
}
.styling-footer {
  margin-top: 36rem;
}
.footerLogoContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.footerLogo {
  width: 8%;
  height: 8%;
} 
.footerlinksContainer {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.footerlink {
  font-family: Montserrat;
  color: #e0e0e0;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;
}
.footerlink:hover {
  text-decoration: none;
  color: #e0e0e0;
}
.logosContainer { 
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 40px;
}
.twitterlogo {
  margin-right: 7px;
}
.facebooklogo {
  margin-right: 7px;
}
.instagramlogo {
  margin-right: 7px;
}

.footerbottomBorder {
  border-bottom: 1px solid #4f4f4f;
}

.footerbottomText {
  font-family: Montserrat;
  color: #e0e0e0;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0px; 
  text-align: center;
}
@media only screen and (max-width: 500px) {
  .footerlink {
    font-size: 14px !important;
  }
  .footerLogo {
    width: 13%;
  }
}
@media only screen and (max-width: 450px) {
  .footerlinksContainer {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
  }
  .columnDirection {
    text-align: center;
    display: flex;
    justify-content: center;
  } 
  .footerlink {
    font-size: 17px;
    margin-bottom: 5px;
  }
  .logosContainer {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 370px) {
  .footerbottomText {
    font-size: 10px;
  }
}

@media only screen and (max-width: 1030px) {
  .styling-footer {
    margin-top: 47rem;
  }
}

@media only screen and (max-width: 760px) {
  .styling-footer {
    margin-top: 70rem;
  }
}

@media only screen and (max-width: 400px) {
  .styling-footer {
    margin-top: 82rem;
  }
}

@media only screen and (max-width: 360px) {
  .styling-footer {
    margin-top: 93rem;
  }
}