 
.navbar {
    display: flex;
    flex-direction: row;
  }
  .navbarFixed {
    position: fixed;
    width: 100%;
    height: 105px;
    z-index: 1;
    top: 0;
  }
  .ContactScreennavbarshadowfixedBgcolor {
    background-color: white;
    box-shadow: 5px 4px 8px #F4F4F4;
  }
  .Headerlogo {
    width: 60px; 
  } 
  .navbarshadowContainer {
    display: flex;
    justify-content: space-evenly; 
    align-items: center;
  }
  .buttonContainershadow {
    background: #f9eed7;
    border: 2px solid #e2af46;
    box-sizing: border-box;
    border-radius: 30px !important;
    padding: 3px 35px;
  }
  .buttonContainershadow:focus {
    outline: none;
  }
  .buttonTextshadow {
    font-family: Montserrat;
    color: #e2af46;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
  }
  .buttonTextshadow:hover {
    text-decoration: none;
    color: #e2af46;
  }
  .menubtnContainer {
    background-color: #E2AF46 !important;
  border-radius: 30% !important;
  padding: 6px 10px !important ;
  border: none !important;
  }
  .menubtnContainer:focus {
    outline: none;
  }
  .menubtnText {
    font-family: Montserrat;
    color: #e2af46;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
  }
  .menubtnText:hover {
    text-decoration: none;
    color: #e99d06;
  }
  .whitenavshadowhaederlinks {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    color: #e2af46;
    margin-top: 5px;
  }
  
  .whitenavshadowhaederlinks:hover {
    text-decoration: none;
    color: #e2af46;
  }
  .btnEnd {
    display: flex;
    justify-content: flex-end;
  }
  .navbarNoneshadow {
    display: none;
  }
  @media only screen and (max-width: 992px) {
    .haederlinks {
      font-size: 14px;
    }
    .buttonContainershadow {
      padding: 2px 25px;
    }
    .buttonTextshadow {
      font-size: 15px;
    }
    .navbarshadowContainer {
      display: none;
    }
    .navbarNoneshadow {
      display: block;
    }
  }
  