.subscribebgImage {
  background: url('../../assets/Images/subscribebgImage.png');
  width: 100%;
  height: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 50px;
}
.subscribesectionConatiner {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
}
.subscribecompoImage {
  width: 100%;
  height: 100%;
}
.headinsConatiner {
  margin-left: -98px;
}
.signupText {
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0.5px;
  color: white;
}

.updateText {
  color: white;
  font-size: 36px;
  font-weight: 600;
}
.enteremailText {
  font-family: Montserrat;
  color: white;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0px;
  text-align: center;
}
.emailsubsceibebtnContainer {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 10px;
}
.emailInput {
  background-color: white;
  height: 50px;
  width: 365px;
  outline: none;
  border: none;
  padding: 5px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.emailInput::placeholder {
  font-family: Montserrat;
  padding-bottom: 5px;
  color: #bdbdbd;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
}
.btnbgColor {
  background: #e2af46;
  padding: 13px 28px;
  font-weight: bold;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border: none;
  outline: none;
}
.btnbgColor:focus {
  outline: none;
  border: none;
}
.subscribebtnText {
  font-family: Montserrat;
  color: #2e3c40;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: center;
}
.subscribebtnText:hover {
  text-decoration: none;
  color: #2e3c40;
}
@media only screen and (max-width: 1199px) {
  .subscribecompoImage {
    display: none;
  }
  .headinsConatiner {
    margin-left: 0;
  }
}
@media only screen and (max-width: 992px) {
  .subscribesectionConatiner {
    margin-top: 10px;
  }
  .signupText {
    font-size: 30px !important;
  }
  .updateText {
    font-size: 30px;
    line-height: 10px;
  }
  .enteremailText {
    font-size: 26px;
    font-weight: 600;
    line-height: 35px;
  }
}
@media only screen and (max-width: 550px) {
  .updateText {
    font-size: 26px;
    line-height: 10px !important;
  }
  .enteremailText {
    margin-top: 1rem;
    font-size: 20px !important;
    letter-spacing: 1px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 469px) {
  .subscribesectionConatiner {
    margin-top: 10px;
  }
  .signupText {
    font-size: 22px;
    margin-bottom: 0;
  }
  .emailInput {
    height: 35px;
    width: 250px;
  }
  .btnbgColor {
    padding: 5.5px 20px;
  }
}
@media only screen and (max-width: 390px) {
  .emailInput {
    height: 30px;
    width: 200px;
  }
  .btnbgColor {
    padding: 3px 12px;
  }
  .emailInput::placeholder {
    font-size: 13px;
  }
  .subscribebtnText {
    font-size: 14px;
  }
  .updateText {
    font-size: 20px;
  }
  .enteremailText {
    font-size: 18px !important;
  }
}
