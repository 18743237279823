.ant-modal-body {
  padding: 20px !important;
}
.modalOneHeading {
  font-size: 17px;
  padding-top: 2rem;
  font-weight: 700;
  text-align: center;
}
.modalOneParagraph {
  font-size: 15px;
  font-weight: 300;
  text-align: center;
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}
.modalHomepageBtn {
  border-radius: 8px;
  height: 45px;
  letter-spacing: 0.01em;
  text-align: center;
  color: BLACK;
  margin-top: 1rem;
  width: 80%;
  border: none;
  padding-top: 0.8rem;
  background-color: #e2af46;
  margin: auto;
}
.modalHomapageBtnName {
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.centerTickCircle {
  text-align: center !important;
  display: flex;
  justify-content: center;
  margin-top: -3.4rem;
}
.tickCircle {
  background-color: #fff;
  box-shadow: 0px 2px 2px #f2f2f2;
  border-radius: 50%;
  width: 15%;
  height: 70px;
  text-align: center;
}
.tickCirlceText {
  padding-top: 1rem;
}
