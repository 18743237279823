.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
}
.textfullContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.makeAnImpactText {
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0.20000000298023224px;
}
.helpBringText {
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0.20000000298023224px;
}
.donateImageContaner {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.donateImage {
  width: 70%;
  height: 70%;
  margin-top: 50px;
}
.donatebtnContainer {
  background: #e2af46;
  border-radius: 8px;
  padding: 16px 38px;
  border: none; 
}
.donatebtnText {
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #333333;
}
.donatebtnText:hover {
  text-decoration: none;
  color: #333333;
}
.donatebottomborder {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e0e0e0;
}
@media only screen and (max-width: 1199px) {
  .donateImage {
    width: 80%;
    height: 80%;
  }
}
@media only screen and (max-width: 992px) {
  .donateImage {
    width: 89%;
    height: 89%;
  }
  .textfullContainer {
    margin-top: 60px;
  }
  .textContainer {
    margin-top: 0%;
  }
  .donateImage {
    margin-top: 30%;
  }
  .makeAnImpactText {
    font-size: 32px;
  }
  .helpBringText {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .donatebottomborder {
    flex-direction: column;
  }
  .makeAnImpactText {
    margin-top: -10rem;
    font-size: 28px;
    text-align: center;
  }
  .helpBringText {
    font-size: 28px;
    text-align: center;
  }
  .donatebtnContainer {
    margin: auto;
  }
  .donateImage {
    margin-top: 15%;
  }
}
@media only screen and (max-width: 500px) {
  .makeAnImpactText {
    font-size: 24px;
    margin-top: 0px;
  }
  .helpBringText {
    font-size: 24px; 
  }
  .donatebtnContainer {
    padding: 5px 20px;
  }
  .donatebtnText {
    font-size: 14px;
  }
}

@media only screen and (max-width: 538px) {
  .makeAnImpactText { 
    margin-top: -17rem;
  }
}

@media only screen and (max-width: 430px) {
  .makeAnImpactText {
    margin-top: -13rem !important;
    font-size: 18px;
    margin-top: 0px;
  }
  .helpBringText {
    font-size: 18px;
    line-height: 30px; 
  }
  .donatebtnContainer {
    margin-top: -2rem;
  }
}