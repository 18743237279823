
.aboutUsText {
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
}
.subHeadingAboutSection {
    font-family: Montserrat;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0.5px;
    text-align: left;
    margin-top: 1rem;
}
.TextAboutSection {
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 1rem;
}
.TextAboutSectionTwo {
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 1rem;
}
.TextAboutSectionThree {
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 1rem;
}
.TextAboutSectionFour {
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.coreValuesImg {
    width: 100%;
    margin-left: 30px;
}
.coreValuesSection{
    display: flex;
    align-items: center;
}
.coreValuesText {
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    align-items: center;
    padding: 2rem;
}
.orderVisionSection {
    display: flex;
}
.ourVisionImg {
    width: 100%;
    margin-left: -0px;
}
.ourVisionSection{
    display: flex;
    align-items: center;
}
.ourVisionText {
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0.20000000298023224px;
    text-align: right;
    align-items: center;
    padding: 2rem;
}
@media only screen and (max-width: 1030px) {
    .coreValuesText {
      font-size: 26px;
    }
    .ourVisionText {
        font-size: 28px;
    }
}
@media only screen and (max-width: 995px) {
    .coreValuesImg {
        margin-left: 15px;
        height: 240px;
        margin-top: 1.1rem;
    }
    .ourVisionImg {
        margin-top: -1rem;
        margin-left: -15px;
        height: 240px;
    }
    .coreValuesText {
        font-size: 28px;
        line-height: 42px;
    }
    .ourVisionText {
        font-size: 28px;
        line-height: 42px;
    }
}
@media only screen and (max-width: 770px) {
    .subHeadingAboutSection {
        font-size: 34px !important;
    }
    .TextAboutSection {
        font-size: 20px !important;
    }
    .TextAboutSectionTwo {
        font-size: 20px !important;
    }
    .TextAboutSectionThree {
        font-size: 20px !important;
    }
    .TextAboutSectionFour {
        font-size: 20px !important;
    }
}
@media only screen and (max-width: 767px) {
    .orderVisionSection {
        display: flex;
        flex-direction: column;
    }
    .ourVisionSection {
        order: 2;
    }
    .coreValuesImg {
        margin: auto;
    }
    .ourVisionImg {
        margin: auto;
    }
    .ourVisionText {
        text-align: left;
    }
}
@media only screen and (max-width: 380px) {
    .coreValuesImg {
        height: 200px;
    }
    .ourVisionImg {
        height: 200px; 
    }
}
@media only screen and (max-width:454px) {
    .providingText {
        font-size: 24px !important;
        font-weight: 600 !important;
    }
    .subHeadingAboutSection {
        font-size: 28px !important;
    }
    .TextAboutSection {
        font-size: 17px !important;
    }
    .TextAboutSectionTwo {
        font-size: 17px !important;
    }
    .TextAboutSectionThree {
        font-size: 17px !important;
    }
    .TextAboutSectionFour {
        font-size: 17px !important;
    }
    .coreValuesText { 
        font-size: 17px !important; 
        line-height: 30px !important;
    }
    .ourVisionText {
        font-size: 17px !important;
        line-height: 30px !important;
    }
}
@media only screen and (max-width: 330px) {
    .subHeadingAboutSection {
        font-size: 22px !important;
    }
}