@media only screen and (max-width: 550px) {
  .slick-slider {
    width: 90vw !important;
  }
}

.slick-arrow.slick-next {
  display: block;
  width: 63px;
  border-bottom-right-radius: 8px;
  height: 40px;
  right: 0px;
  margin-top: 201px;
}
.slick-arrow.slick-next:before {
  content: '→';
  font-size: 36px;
  line-height: 1.3; 
  margin-left: 13px;
  color: grey;
}
.slick-arrow.slick-prev {
  display: block;
  width: 63px;
  border-top-left-radius: 8px;
  height: 40px;
  left: 983px;
  z-index: 1;
  top: 428px;
}
.slick-arrow.slick-prev:before {
  content: '←';
  font-size: 36px;
  line-height: 1.3;
  margin-left: 13px;
  color: grey;
  z-index: 1;
}

@media only screen and (max-width: 1200px) {
  .slick-arrow.slick-prev {
    left: 803px;
    top: 355px;
  }
  .slick-arrow.slick-next {
    margin-top: 165px;
  }
}
@media only screen and (max-width: 992px) {
  .slick-arrow.slick-prev {
    left: 563px;
    top: 258px;
  }
  .slick-arrow.slick-next {
    margin-top: 116px;
  }
}
@media only screen and (max-width: 768px) {
  .slick-arrow.slick-prev {
    left: 563px;
    top: 259px;
  }
  .slick-arrow.slick-next {
    margin-top: 117px;
  }
}
@media only screen and (max-width: 767px) {
  .slick-arrow.slick-prev {
    left: 383px;
    top: 186px;
  }
  .slick-arrow.slick-next {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 600px) {
  .slick-arrow.slick-next {
    display: block;
    width: 0px;
    height: 0px;
    right: 0px;
    margin-top: 80px;
  }
  .slick-arrow.slick-prev {
    display: block;
    width: 0px;
    height: 0px;
    left: 383px;
    top: 186px;
  }
  .slick-arrow.slick-prev:before {
    display: none;
  }
  .slick-arrow.slick-next:before {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .slick-dots li button:before {
    display: none;
    background-color: red;
  }
}
 