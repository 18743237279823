.headerbgImage {
  background: url('https://res.cloudinary.com/aro-group/image/upload/v1611779302/imgpsh_fullsize_anim_p53jbt.png');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 8%;
}
.textContainer {
  justify-content: flex-start;
  margin-top: 15%;
  margin-bottom: 10px;
  padding: 20px;
}
.projectscreenoneheaderText {
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 29px;
  letter-spacing: 1px;
  text-align: left;
  color: #e2af46;
}
.providingText {
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 1px;
  text-align: left;
  color: #e2af46;
}
@media only screen and (max-width: 535px) {
  .providingText {
    font-size: 28px;
  }
  .textContainer {
    margin-top: 25%;
  }
}
@media only screen and (max-width: 460px) {
  .projectscreenoneheaderText {
    font-size: 22px;
  }
  .providingText {
    font-size: 20px;
    line-height: 40px;
  }
  .textContainer {
    margin-top: 40%;
  }
}
@media only screen and (max-width: 370px) {
  .projectscreenoneheaderText {
    font-size: 20px;
  }
  .providingText {
    font-size: 16px;
    line-height: 30px;
  }
  .textContainer {
    margin-top: 40%;
  }
}
