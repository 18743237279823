.backgroundimage {
  background-image: url('https://res.cloudinary.com/aro-group/image/upload/v1611778314/IMG_1683_n32pff.jpg');
  /* background-image: url('/src/assets/Images/Headerbgimg.JPG'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.backgroundimage:before {
  background: #000000;
  opacity: 0.35;
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.providingContainer {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.provideText {
  color: white;
  font-family: Montserrat;
  font-size: 55px;
  font-style: normal;
  font-weight: bold;
  line-height: 72px;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 30%;
}
.videologocontainer {
  align-items: center;
  display: flex;
  justify-content: center;
}
.videologo {
  margin-right: 5px;
  width: 80%;
}
.videolink {
  color: white;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0px;
}
.videolink:hover {
  text-decoration: none;
  color: white;
}
@media only screen and (max-width: 992px) {
  .provideText {
    font-size: 40px;
    line-height: 52px;
  }
  .iframe {
    height: 300px !important;
  }
  .iframe {
    height: 400px !important;
  }
}
@media only screen and (max-width: 770px) {
  .provideText {
    font-size: 32px;
    line-height: 48px;
    margin-top: 35%;
  }
}
@media only screen and (max-width: 500px) {
  .provideText {
    margin-top: 38%;
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 0;
  }
  .iframe {
    height: 300px !important;
  }
}
@media only screen and (max-width: 450px) {
  .provideText {
    margin-top: 41%;
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 0;
  }
  .iframe {
    height: 250px !important;
  }
}
