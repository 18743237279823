.sayHalloText {
  color: #4f4f4f;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0px;
  margin-top: 50px;
  text-align: left;
}
.contactUsText {
  color: '#333333';
  font-family: Montserrat;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0.5px;
  text-align: left;
}
.questionsText {
  color: '#4f4f4f';
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 37px;
  letter-spacing: 0px;
  text-align: left;
}

.fullnameInputfield {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 2px solid #bdbdbd;
}
.fullnameInputfield::placeholder {
  padding-top: 6px;
}
.fullnameInputfield:focus {
  outline: none;
}
.emailInputfield {
  margin-top: 20px;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 2px solid #bdbdbd;
}
.emailInputfield::placeholder {
  padding-top: 6px;
}
.emailInputfield:focus {
  outline: none;
}
.messageinputfield {
  margin-top: 20px;
  width: 100%;
  height: 100px;
  padding: 12px;
  border-radius: 8px;
  border: 2px solid #bdbdbd;
}
.messageinputfield:focus {
  outline: none;
}
.messageinputfield::placeholder {
  padding-top: 6px;
}
::placeholder {
  font-family: Montserrat;
  color: '#BDBDBD';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
}
.submitbtnContainer {
  font-weight: bold;
  background: #e2af46;
  border-radius: 8px;
  padding: 18px 48px;
  border: none;
  margin-top: 20px;
}
.submitbtnContainer:focus {
  outline: none;
}
.submitbtnText {
  font-family: Montserrat;
  color: #2e3c40;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: center;
}
.submitbtnText:hover {
  text-decoration: none;
  color: #2e3c40;
}
.mapImageContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 70px;
}
.mapImage {
  width: 90%;
  height: 80%;
}
.phoneContainer {
  margin-top: -2rem;
}
.faxContainer {
  margin-top: -2rem;
}
.firstContainer {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  margin-top: 5px;
}
.thirtyWidthfirs {
  width: 30%;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 5px;
}
.sixtyWidthfirst {
  width: 70%;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;
  margin-top: 5px;
}
.thirtyWidthsecond {
  width: 25%;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
.sixtyWidthsecond {
  width: 50%;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.thirtyWidthfourth {
  width: 27%;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 5px;
}
.sixtyWidthfourth {
  width: 70%;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;
  margin-top: 5px;
}
.thirtyWidthfifth {
  margin-top: 52px;
  width: 15%;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
.sixtyWidthfifth {
  margin-top: 51px;
  width: 50%;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.directionColumn {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1199px) {
  .directionColumn {
    display: flex;
    flex-direction: column;
  }
  .thirtyWidthfirs {
    width: 50%;
    margin-bottom: 0;
  }
  .sixtyWidthfirst {
    width: 100%;
  }
  .thirtyWidthsecond {
    width: 50%;
  }
  .sixtyWidthsecond {
    width: 70%;
  }
  .thirtyWidthfourth {
    width: 50%;
    margin-bottom: 0;
  }
  .sixtyWidthfourth {
    width: 70%;
  }
  .thirtyWidthfifth {
    width: 40%;
    margin-bottom: 0;
  }
  .sixtyWidthfifth {
    width: 65%;
    margin-top: 0;
  }
}

@media only screen and (max-width: 992px) {
  .mapImageContainer {
    margin-top: 50%;
  }
  .mapImage {
    width: 100%;
    height: 100%;
  }
  .firstContainer {
    margin-left: 20px;
  }
  .contactUsText {
    font-size: 34px;
  }
  .questionsText {
    font-size: 21px;
  }
  .sixtyWidthsecond {
    width: 70%;
  }
  .sixtyWidthfifth {
    width: 70%;
  }
}
@media only screen and (max-width: 769px) {
  .mapImageContainer {
    margin-top: 50%;
  }
  .sixtyWidthsecond {
    width: 70%;
  }
  .sixtyWidthfifth {
    width: 70%;
  }
  .contactUsText {
    font-size: 32px;
  }
  .questionsText {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .mapImageContainer {
    margin-top: 50px;
  }
  .sixtyWidthsecond {
    width: 55%;
  }
  .sixtyWidthfifth {
    width: 55%;
  }
  .thirtyWidthfifth {
    margin-top: 23px;
  }
}
@media only screen and (max-width: 420px) {
  .sixtyWidthsecond {
    width: 60%;
  }
  .sixtyWidthfifth {
    width: 60%;
  }
  .sixtyWidthfirst {
    font-size: 11px;
  }
}
@media only screen and (max-width: 391px) {
  .sixtyWidthsecond {
    width: 65%;
  }
  .sixtyWidthfifth {
    width: 66%;
  }
}
@media only screen and (max-width: 500px) {
  .contactUsText {
    font-size: 26px;
    margin-bottom: 0px;
  }
  .questionsText {
    font-size: 17px;
  }
}
@media only screen and (max-width: 377px) {
  .sayHalloText {
    font-size: 22px;
  }
  .submitbtnContainer {
    padding: 6px 25px;
    margin-top: 15px;
  }
  .sixtyWidthsecond {
    width: 65%;
  }
  .sixtyWidthfifth {
    width: 64%;
  }
  .thirtyWidthfifth {
    margin-top: 26px;
  }
  .firstContainer {
    margin-left: 6px;
  }
}
@media only screen and (max-width: 338px) {
  .sixtyWidthfirst {
    width: 107%;
  }
  .sixtyWidthsecond {
    width: 75%;
  }
  .sixtyWidthfifth {
    width: 75%;
  }
  .thirtyWidthfifth {
    margin-top: 50px;
  }
}
