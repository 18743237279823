.aboutusText {
  font-family: Montserrat;
  color: #4f4f4f;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0px;
  margin-top: 40px;
}
.helpgoalHaeding {
  font-family: Montserrat;
  color: #4f4f4f;
  color: #333333;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0.5px;
}
.helpgoalfirstParagraph {
  font-family: Montserrat;
  color: #4f4f4f;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.20000000298023224px;
}
.helpgoaltwoParagraph {
  font-family: Montserrat;
  color: #4f4f4f;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.20000000298023224px;
}
.aboutusRightimage {
  display: flex;
  justify-content: center;
  align-items: center;
}
.largeContainer {
  display: flex;
  align-items: center;
  margin-top: 45px;
  margin-bottom: 40px;
  justify-content: center;
}
.aboutusImage {
  width: 90%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.moreaboutbtnContainer {
  background: #e2af46;
  border-radius: 8px;
  padding: 16px 30px;
  outline: none;
  border: none;
  margin-top: 20px;
  margin-bottom: 40px;
} 
.moreaboutbtnContainer:hover {
  outline: none;
  border: none;
}
.moreaboutbtnContainer:focus {
  outline: none;
  border: none;
}
.moreaboutText {
  color: #333333;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: center;
}
.moreaboutText:hover {
  text-decoration: none;
  color: #333333;
}
.bottomBorder {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e0e0e0;
}
@media only screen and (max-width: 1199px) {
  .aboutusImage {
    width: 100%;
    height: 100%;
    margin-left: 40px;
  }
  .helpgoalHaeding {
    font-size: 38px !important;
  }
}
@media only screen and (max-width: 991px) {
  .aboutusImage {
    width: 100%;
    height: 100%;
    margin-left: 40px;
  }
  .aboutusText {
    font-size: 20px;
  }
  .helpgoalHaeding {
    font-size: 28px !important;
  }
  .helpgoalfirstParagraph {
    font-size: 17px;
  }
  .helpgoaltwoParagraph {
    font-size: 17px;
  }
  .moreaboutbtnContainer {
    margin-top: 5px;
  }
}
@media only screen and (max-width: 767px) {
  .aboutusImage {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
  .bottomBorder {
    flex-direction: column;
  }
}
@media only screen and (max-width: 354px) {
  .aboutusText {
    font-size: 18px;
  }
  .helpgoalHaeding {
    font-size: 30px;
  }
  .helpgoalfirstParagraph {
    font-size: 16px;
  }
  .helpgoaltwoParagraph {
    font-size: 16px;
  }
  .moreaboutbtnContainer {
    margin-top: 3px;
  } 
  .moreaboutbtnContainer {
    padding: 3px 16px;
  }
  .moreaboutText {
    font-size: 14px;
  }
}
@media only screen and (max-width: 334px) {
  .helpgoalHaeding {
    font-size: 28px;
  }
}
@media only screen and (max-width: 450px) {
  .moreaboutbtnContainer {
    margin-bottom: 0;
  } 
  .helpgoalHaeding {
    font-size: 24px !important;
  }
  .helpgoalfirstParagraph {
    font-size: 15px;
  }
  .helpgoaltwoParagraph {
    font-size: 15px;
  }
}
