.homeDonateLinks {
  text-align: center;
  margin-top: 9rem;
}
.homeLinkOne {
  color: #e2af46;
}
.donatePageLinkTwo {
  color: black;
}
.donateEmptyImg {
  width: 100%;
}
.donatepageEmpty {
  margin-top: 9rem;
  background-color: red;
}
.headingDonatePage {
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.subHeadingDonatePage {
  font-family: Montserrat;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-top: 2rem;
  padding-right: 5rem;
}
.DropdownText {
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 1rem;
  color: #333333;
}
.selectDropdown {
  margin-top: 8px;
  width: 80%;
  padding: 6px;
  border: 2px solid #4f4f4f;
  box-sizing: border-box;
  border-radius: 8px;
}
.btnText {
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 8px;
  color: #333333;
}
.packagesDonatePageEmpty {
  display: flex;
  margin-top: 1rem;
}
.packageFiftyDollars {
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #bdbdbd;
  background: #f2f2f2;
  border: 2px solid #bdbdbd;
  border-radius: 8px;
  width: 80px;
  height: 45px;
  padding: 9px;
}
.packageHundredDollars {
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #bdbdbd;
  background: #f2f2f2;
  border: 2px solid #bdbdbd;
  border-radius: 8px;
  width: 80px;
  height: 45px;
  padding: 9px;
  margin-left: 1rem;
}
.packageTwoHundredFiftyDollars {
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #bdbdbd;
  background: #f2f2f2;
  border: 2px solid #bdbdbd;
  border-radius: 8px;
  width: 80px;
  height: 45px;
  padding: 9px;
  margin-left: 1rem;
}
.packageThreeHundredDollars {
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #bdbdbd;
  background: #f2f2f2;
  border: 2px solid #bdbdbd;
  border-radius: 8px;
  width: 80px;
  height: 45px;
  padding: 9px;
  margin-left: 1rem;
}
.linkOnePackage:hover {
  text-decoration: none;
}
.linkTwoPackage:hover {
  text-decoration: none;
}
.linkThreePackage:hover {
  text-decoration: none;
}
.linkFourPackage:hover {
  text-decoration: none;
}
.packageFiftyDollars:hover {
  background-color: #e2af46;
  color: black;
}
.packageHundredDollars:hover {
  color: black;
  background-color: #e2af46;
}
.packageTwoHundredFiftyDollars:hover {
  color: black;
  background-color: #e2af46;
}
.packageThreeHundredDollars:hover {
  color: black;
  background-color: #e2af46;
}

.packageFiftyDollars:active {
  background-color: #e2af46;
  color: black;
}
.packageHundredDollars:active {
  color: black;
  background-color: #e2af46;
}
.packageTwoHundredFiftyDollars:active {
  color: black;
  background-color: #e2af46;
}
.packageThreeHundredDollars:active {
  color: black;
  background-color: #e2af46;
}

.btnTextSubmit:hover {
  color: black;
  background-color: #e2af46;
}
.btnTextSubmit:active {
  color: black;
  background-color: #e2af46;
}
.inputFeild:focus {
  outline: none;
}

.inputFeild {
  width: 80%;
  background: #f2f2f2;
  border: 2px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 1.4rem;
  padding: 6px;
}
.inputFeild::placeholder {
  color: black;
  padding-left: 0.4rem;
  padding-top: 5px;
}
.btnTextTwo {
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 1rem;
  color: #333333;
}
.frequencyTime {
  display: flex;
  margin-top: 1rem;
}
.oneTime {
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  background: #f2f2f2;
  border: 2px solid #bdbdbd;
  color: #bdbdbd;
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-right: 2rem;
}
.recurring {
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  background: #f2f2f2;
  border: 2px solid #bdbdbd;
  color: #bdbdbd;
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 8px;
  padding-bottom: 8px;
}
.btnTextSubmit {
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border-radius: 8px;
  height: 50px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #828282;
  margin-top: 1rem;
  width: 80%;
  border: none;
  /* padding-top: 1rem; */
  background-color: #e0e0e0;
}
@media only screen and (max-width: 992px) {
  .oneTime {
    font-size: 16px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .recurring {
    font-size: 16px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .subHeadingDonatePage {
    padding-right: 2rem;
  }
  .donateEmptyImg {
    margin-top: 5rem;
    height: 70%;
  }
}
@media only screen and (max-width: 500px) {
  .oneTime {
    font-size: 16px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .recurring {
    font-size: 16px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .subHeadingDonatePage {
    padding-right: 2rem;
  }
  .headingDonatePage {
    font-size: 22px;
  }
  .subHeadingDonatePage {
    font-size: 20px;
  }
  .DropdownText {
    font-size: 16px;
  }
  .btnText {
    font-size: 16px;
  }
  .donateEmptyImg {
    margin-top: 5rem;
    height: 70%;
  }
  .packageFiftyDollars {
    font-size: 15px;
    height: 50px;
    width: 50px;
  }
  .packageHundredDollars {
    font-size: 15px;
    height: 50px;
    width: 50px;
    margin-left: 5px;
  }
  .packageThreeHundredDollars {
    font-size: 15px;
    height: 50px;
    width: 50px;
    margin-left: 5px;
  }
  .packageTwoHundredFiftyDollars {
    font-size: 15px;
    height: 50px;
    width: 50px;
    margin-left: 5px;
  }
  .subHeadingDonatePage {
    padding-right: 0rem;
  }
}
@media only screen and (max-width: 992px) {
  .packageThreeHundredDollars {
    width: 60px;
    height: 40px;
    font-size: 16px;
  }
  .packageTwoHundredFiftyDollars {
    width: 60px;
    height: 40px;
    font-size: 16px;
  }
  .packageHundredDollars {
    width: 60px;
    height: 40px;
    font-size: 16px;
  }
  .packageFiftyDollars {
    width: 60px;
    height: 40px;
    font-size: 16px;
  }
}
