.projectText {
  color: #333333;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 49px;
  margin-top: 40px; 
  margin-bottom: 30px;
  letter-spacing: 2px;
  text-align: center;
}
.projectimageContainer {
  display: flex; 
  justify-content: center; 
  align-items: center;
}
.projectImage {
  width: 100%;
  height: 100%;
}
.projectParaOne {
  color: #4f4f4f;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-align: left;
}
.projectParaTwo {
  color: #4f4f4f;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-align: left;
}
.projectParaThree {
  color: #4f4f4f;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.5px;
  margin-bottom: 40px;
  text-align: left;
}
.projectborderBottom {
  border-bottom: 1px solid #e0e0e0;
}
@media only screen and (max-width: 991px) {
  .projectParaOne {
    font-size: 17px !important;
  }
  .projectParaTwo {
    font-size: 17px !important;
  }
  .projectParaThree {
    font-size: 17px !important;
  }
}

@media only screen and (max-width: 770px) {
  .projectText {
    font-size: 33px !important;
  }
}

@media only screen and (max-width: 500px) { 
  .projectText {
    font-size: 28px !important;
  }
  .projectParaOne {
    font-size: 16px !important;
  }
  .projectParaTwo {
    font-size: 16px !important;
  }
  .projectParaThree {
    font-size: 16px !important;
  }
}
 
@media only screen and (max-width: 354px) {
  .projectParaOne {
    font-size: 16px;
  }
  .projectParaTwo {
    font-size: 16px;
  }
  .projectParaThree {
    font-size: 16px;
  }
}
