.navbar {
  display: flex;
  flex-direction: row;
}
.navbarFixed {
  position: fixed;
  width: 100%;
  height: 105px;
  z-index: 45 !important;
  top: 0;
  background: transparent;
}
.navbarfixedBgcolor {
  background-color: white !important;
  opacity: 1;
}
.Headerlogo {
  width: 60px;
}
.navbarContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.buttonContainer {
  background: #f9eed7;
  border: 2px solid #e2af46;
  box-sizing: border-box;
  border-radius: 30px !important;
  padding: 3px 35px;
}
.buttonContainer:focus {
  outline: none;
}
.buttonText {
  font-family: Montserrat;
  color: #e2af46;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
}
.buttonText:hover {
  text-decoration: none;
  color: #e2af46;
}
.menubtnContainer {
  background-color: #e2af46 !important;
  border-radius: 30% !important;
  padding: 6px 10px;
  border: none;
}
.menubtnContainer:focus {
  outline: none;
}
.menubtnText {
  font-family: Montserrat;
  color: black !important;
  font-size: 28px !important;
  font-weight: 300 !important;
  text-align: center;
}
.menubtnText:hover {
  text-decoration: none;
}
.haederlinks {
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  /* color: #fff; */
  margin-top: 5px;
  color: #e2af46;
}

.haederlinks_white-bar {
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  margin-top: 5px;
  color: black;
}

.haederlinks:focus {
  color: #e2af46;
}

.haederlinks_white-bar:focus {
  color: #e2af46;
}

.haederlinks:hover {
  text-decoration: none;
  color: #e2af46;
}
.btnEnd {
  display: flex;
  justify-content: flex-end;
}
.navbarNone {
  display: none;
}
@media only screen and (max-width: 992px) {
  .haederlinks {
    font-size: 14px;
  }
  .buttonContainer {
    padding: 2px 25px;
  }
  .buttonText {
    font-size: 15px;
  }
  .navbarContainer {
    display: none !important;
  }
  .navbarNone {
    display: block !important;
  }
}
