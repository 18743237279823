.aqil .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body {
  background-color:white !important;
}


.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -20px !important; 
  z-index: 1;
}
.drawerhaederlinks {
  margin-bottom: 7px;
  color:#575757;
}
.drawerhaederlinks:focus{
  color: #575757;
  text-decoration: none;
}
.element.style {
  height: 230px;
}
.rightIcon{
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
}
